<template>
  <b-row>
    <b-col sm="10" md="7" lg="7" class="">
      <div class="card px-lg-4 px-md-3 px-2 py-3">
        <div>
          <h6 class="">Name of the Client</h6>
          <!-- <b-form-input id="client" placeholder="" /> -->
          <h5 class="bold">Brain Quest</h5>
        </div>
        <div class="">
          <div class="mt-2">
            <h6 class="">About the Client</h6>
            <h5 class="bold">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo velit
              maiores hic, cum neque sed facilis dolore officia doloremque
              similique, minus aperiam totam placeat. Doloribus voluptatum vitae
              consectetur est repellat?
            </h5>
          </div>
          <div class="mt-2">
            <h6 class="">Company Employee Strength</h6>
            <h5 class="bold">100 - 500</h5>
          </div>
          <div class="mt-3">
            <h4 class="bold">Location Details</h4>
            <div class="mt-2">
              <h6 class="mt-1">Address line 1</h6>
              <h5 class="bold">
                Link Road, Vazira, Borivali, West Mumbai, Maharashtra
              </h5>
            </div>
            <div class="mt-2">
              <h6 class="mt-1">Address line 2</h6>
              <h5 class="bold">
                Next Muttha Chamber, Senapati Bapat Road, Pune, Maharashtra
              </h5>
            </div>
            <b-row class="mt-1">
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">City</h6>
                <h5 class="bold">Maharashtra</h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">State</h6>
                <h5 class="bold">West Mumbai</h5>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">ZIPCODE</h6>
                <h5 class="bold">1700</h5>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="">
                <h6 class="mt-1">Country</h6>
                <h5 class="bold">India</h5>
              </b-col>
            </b-row>
            <div class="mt-3 ml-0 mr-0 mb-2">
              <h4 class="mb-1 bold">Payment Structure</h4>
              <b-col class="border rounded-lg px-2 py-1">
                <div>
                  <h6 class="mt-1">Hourly Pricing (Phone)</h6>
                  <h5 class="bold">$ 1450</h5>
                </div>
                <div>
                  <h6 class="mt-1">Hourly Pricing (Face to Face)</h6>
                  <h5 class="bold">$ 1254</h5>
                </div>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </b-col>

    <b-col md="5" lg="5" class="pr-2 mt-0">
      <!--save draft publish btn - for medium and large device  -->
      <!-- <div class="card p-lg-3 p-2 d-none d-lg-block d-md-block">
          <div class="d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-warning"
              class="mr-2"
            >
              Save and Draft
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class=""
            >
              Save and Publish
            </b-button>
          </div>
        </div> -->
      <div class="card px-lg-3 px-2 pt-2 pb-3">
        <h4 class="bold mt-2">Location Details</h4>
        <div>
          <h6 class="mt-1">Email ID</h6>
          <h5 class="bold">user1@gmail.com</h5>
        </div>
        <div>
          <h6 class="mt-1">Phone Number</h6>
          <h5 class="bold">+48548451</h5>
        </div>
        <div>
          <h6 class="mt-1">Website</h6>
          <h5 class="bold text-secondary">www.brainquest.com</h5>
        </div>
        <!-- <div class="mt-2">
          <h5 class="">Client Contacts</h5>
          <div class="d-flex">
            <div
              v-for="(originator, i) in originators.slice(0, 4)"
              :key="i"
              class=""
            >
              <b-avatar :src="originator.img" class="img" />
            </div>
           
            <b-avatar text="AL" variant="light-secondary" class="" />
            <b-avatar
                v-b-modal.modal-add-originator
                :src="require('@/assets/images/icons/plus-circle@2x.png')"
                variant="light-secondary"
                class="ml-1"
              />
          </div>
        </div> -->
        <div class="mt-3">
          <h4 class="bold">Social Presence</h4>
          <div class="mt-2 mb-1">
            <h5 class="">Linkedin</h5>
            <h6 class="bold">www.linkedin.com/brainquest</h6>
          </div>
          <div class="my-1">
            <h5 class="">Facebook</h5>
            <h6 class="bold">www.facebook.com/brainquest</h6>
          </div>
          <div class="my-1">
            <h5 class="">Instagram</h5>
            <h6 class="bold">www.instagram.com/brainquest</h6>
          </div>
          <div class="my-1">
            <h5 class="">Skype</h5>
            <h6 class="bold">www.skype.com/brainquest</h6>
          </div>
          <!-- <div class="mt-3">
            <h4 class="bold">Attach Files</h4>
            <h5 class="bold">abcd.png</h5>
          </div> -->
        </div>
      </div>
    </b-col>

    <b-col cols="12" class="">
      <div class="card px-lg-3 px-2 pt-2 py-2">
        <h4 class="pt-1 bold">Other Details</h4>
        <b-row>
          <b-col cols="12" class="my-1">
            <h6 class="">Notes about the Client</h6>
            <h5 class="bold">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo velit
              maiores hic, cum neque sed facilis dolore officia doloremque
              similique, minus aperiam totam placeat. Doloribus voluptatum vitae
              consectetur est repellat?
            </h5>
          </b-col>
        </b-row>
      </div>
    </b-col>

    <!-- button for small device only  -->
    <!-- <b-col cols="11" class="d-lg-none d-md-none d-block card p-2 mx-auto">
        <div class="d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning"
            class="mr-2"
          >
            Save and Draft
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class=""
          >
            Save and Publish
          </b-button>
        </div>
      </b-col> -->
  </b-row>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BImg,
  BRow,
} from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCard,
    BCardText,
    BCol,
    BImg,
    BButton,
    BAvatar,
  },

  data() {
    return {
      clients: [
        { img: `${require('@/assets/images/portrait/small/avatar-s-1.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-2.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-3.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-4.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-5.jpg')}` },
      ],
      originators: [
        { img: `${require('@/assets/images/portrait/small/avatar-s-1.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-2.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-3.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-4.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-5.jpg')}` },
      ],
      projectManagers: [
        { img: `${require('@/assets/images/portrait/small/avatar-s-6.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-7.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-8.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-9.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-10.jpg')}` },
      ],
      researchers: [
        { img: `${require('@/assets/images/portrait/small/avatar-s-11.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-12.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-13.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-14.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-15.jpg')}` },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}
.bold {
  font-weight: 700;
}
.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
.img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}

.file_bg {
  background-color: #dcdcdc;
}
</style>
